import React from 'react';
import { useFormik } from 'formik';
import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import * as Yup from 'yup';
// import FileUpload from "react-mui-fileuploader";

import httpClient from '../httpClient';
// import { baseURL } from '../config/constants/url';


const ResetMessageForm = (props) => {

    const validationSchema = Yup.object({
        imei: Yup.string().required('IMEI is required'),
        firmwareType: Yup.string().required('Firmware type is required'),
    });

    const formik = useFormik({
        initialValues: {
            imei: "",
            firmwareType: "cm_afu",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            httpClient.post('/device/clear-fw-update-message/' + values.imei + '/' + values.firmwareType)
                .then((res) => {
                    alert("Successfully cleared firmware update message!");
                }).catch((err) => {
                    alert("There was a problem with the upload: " + err.message);
                });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Container component={Paper} sx={{marginTop: 6, padding: 2}}>
                <h4>Clear Firmware Update Message</h4>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            id="fwclear_imei"
                            name="imei"
                            label="Enter Device IMEI"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.imei}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            labelId='fwclear_firmwareType'
                            select
                            id="fwclear_firmwareType"
                            name='firmwareType'
                            value={formik.values.firmwareType}
                            label="Firmware Type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            required
                        >
                            <MenuItem value="cm_afu">Cloud Module Application Firmware Update (cm_afu)</MenuItem>
                            <MenuItem value="cm_dmfu">Cloud Module Modem Delta Firmware Update (cm_dmfu)</MenuItem>
                            <MenuItem value="cm_fmfu">Cloud Module Modem Full Firmware Update (cm_fmfu)</MenuItem>
                            <MenuItem value="eh_afu">Energy Harvester Application Firmware Update (eh_afu)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={formik.handleSubmit}>Clear Firmware Update Message</Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    )
}

const validationSchema = Yup.object({
    versionString: Yup.string().required('Version string is required'),
    imei: Yup.string().required('IMEI is required'),
    firmwareType: Yup.string().required('Firmware type is required'),
    firmwareFile: Yup.mixed().when('firmwareType', {
        is: 'cm_afu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_afu**.bin)',
            (value) => value && value.name.startsWith('hubo_cm_afu') && value.name.endsWith('.bin')
        ),
    }).when('firmwareType', {
        is: 'cm_dmfu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_dmfu**.bin)',
            (value) => value && value.name.startsWith('hubo_cm_dmfu') && value.name.endsWith('.bin')
        ),
    }).when('firmwareType', {
        is: 'cm_fmfu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_fmfu**.cbor)',
            (value) => value && value.name.startsWith('hubo_cm_fmfu') && value.name.endsWith('.cbor')
        ),
    }).when('firmwareType', {
        is: 'eh_afu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_eh_afu**.hex)',
            (value) => value && value.name.startsWith('hubo_eh_afu') && value.name.endsWith('.hex')
        ),
    }),
});

const ManageFirmwareView = () => {
    // const [firmwareFile, setFirmwareFile] = useState();
    const formik = useFormik({
        initialValues: {
            updateMethod: 'old',
            versionString: "",
            firmwareType: 'cm_afu',
            imei: "",
            firmwareFile: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            let postUrl;
            if (values.firmwareType === "cm_afu") {
                postUrl = '/device/upload-cm-firmware/' + values.imei;
            } else if (values.firmwareType === "eh_afu") {
                postUrl = '/device/upload-eh-firmware/' + values.imei;
            } else if (values.firmwareType === "cm_dmfu") {
                postUrl = '/device/upload-cm-mfu/' + values.imei;
            } else if (values.firmwareType === "cm_fmfu") {
                postUrl = '/device/upload-cm-fmfu/' + values.imei;
            }
            httpClient.post(
                // new URL(postUrl, baseURL),
                postUrl,
                formData
            ).then((res) => {
                alert("Firmware deployment successfully pushed!");
            }).catch((err) => {
                alert("There was a problem with the upload: " + err.message);

            });
        },
    });

    const handleFileChange = (e) => {
        // setFirmwareFile(file);
        formik.setFieldValue('firmwareFile', e.currentTarget.files[0]);
    }

    let acceptedFirmwareFiletype;
    switch (formik.values.firmwareType) {
        case 'cm_afu':
            acceptedFirmwareFiletype = '.bin';
            break;
        case 'cm_dmfu':
            acceptedFirmwareFiletype = '.bin';
            break;
        case 'cm_fmfu':
            acceptedFirmwareFiletype = '.cbor';
            break;
        case 'eh_afu':
            acceptedFirmwareFiletype = '.hex';
            break;
        default:
            acceptedFirmwareFiletype = '.bin, .hex, .cbor';
    }


    return (
        <>
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Container component={Paper} sx={{padding: 2}}>
                    <h4>Deploy Firmware</h4>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id='updateMethod'>Update Method</InputLabel>
                                <Select
                                    labelId='updateMethod'
                                    label="Update Method"
                                    id='updateMethod'
                                    name='updateMethod'
                                    value={formik.values.updateMethod}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value='old'>Old Method (Static File Path)</MenuItem>
                                    <MenuItem value='new'>New Method (Dynamic File Path)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                type="text"
                                id="imei"
                                name="imei"
                                label="Enter Device IMEI"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.imei}
                                required
                                fullWidth
                                error={formik.touched.imei && Boolean(formik.errors.imei)}
                                helperText={formik.touched.imei && formik.errors.imei}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                labelId='firmwareType'
                                select
                                id="firmwareType"
                                name='firmwareType'
                                value={formik.values.firmwareType}
                                label="Firmware Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                required
                            >
                                <MenuItem value="cm_afu">Cloud Module Application Firmware Update (cm_afu)</MenuItem>
                                <MenuItem value="cm_dmfu">Cloud Module Modem Delta Firmware Update (cm_dmfu)</MenuItem>
                                <MenuItem value="cm_fmfu">Cloud Module Modem Full Firmware Update (cm_fmfu)</MenuItem>
                                <MenuItem value="eh_afu">Energy Harvester Application Firmware Update (eh_afu)</MenuItem>
                            </TextField>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                id="versionString"
                                name="versionString"
                                label='Enter New Firmware Version String (without afw_ prefix, e.g. "3.6.25")'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.versionString}
                                required
                                fullWidth
                                error={formik.touched.versionString && Boolean(formik.errors.versionString)}
                                helperText={formik.touched.versionString && formik.errors.versionString}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <FileUpload
                            multiFile={false}
                            onFilesChange={handleFilesChange}
                            onContextReady={(context) => {}}
                        /> */}
                            <label>Select Firmware File...</label>
                            <input
                                type="file"
                                id="firmwareFile"
                                name="firmwareFile"
                                accept={acceptedFirmwareFiletype}
                                onChange={handleFileChange}
                                onBlur={formik.handleBlur}
                                style={{ width: '100%' }}
                            />
                            {formik.touched.firmwareFile && formik.errors.firmwareFile ? (
                                <FormHelperText error>{formik.errors.firmwareFile}</FormHelperText>
                            ) : null}
                            <br></br>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit">Deploy Firmware</Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>

            <ResetMessageForm />
        </>
    )
}

export default ManageFirmwareView;